.activity {
    grid-column: leftgutter-start / rightgutter-end;
    background-color: #fff;
    padding-top: 7rem;
    
    display: grid;
    grid-template-columns: [leftgutter-start] minmax(13rem,1fr) [leftgutter-end full-start] repeat(12, [col-start] minmax(min-content, 1fr) [col-end]) [full-end rightgutter-start] minmax(13rem,1fr) [rightgutter-end];
    grid-gap: 1.6;

    @media only screen and (max-width: 550px) {
        display: block;
    }

    &__container {
        grid-column: full-start / full-end;
   
        @media only screen and (max-width: 550px) {
            margin: 0 7rem;
        }
    }

    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 4rem;
        width: 100%;
        @media only screen and (max-width: 450px) {
            flex-direction: column;
        }

        &-desc {
            display: flex;
            align-items: center;
        width: 100%;

        }

        &-img {
            height: 15rem;
            width: 15rem;
            object-fit: cover;
            border-radius: 50%;
            margin-right: 3.5rem;

            @media only screen and (max-width: 830px) {
                height: 10rem;
                width: 10rem;
                margin-right: 1rem;
            }
        }

        &-social {
            display: flex;
            column-gap: 2rem;

            @media only screen and (max-width: 830px) {
                column-gap: 1rem;

            }

            @media only screen and (max-width: 750px) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                row-gap: 1rem;
            }

            @media only screen and (max-width: 665px) {
                grid-template-columns: 1fr ;
            }

            @media only screen and (max-width: 450px) {
                display: flex;
                margin-top: 3.7rem;
            }
        }
    }

    &__rating {
        display: flex;
        align-items: center;
        margin-top: 1.6rem;
        & p {
            margin-left: 3rem;
        }
    }

    &__desc {
        width: 80%;
        margin-bottom: 5rem;

        @media only screen and (max-width: 750px) {
            width: 100%;
        }

    }

    &__amenities {
        display: flex;

    }

    &__amen {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 4.5rem;
        
        &:not(:last-child) {
            margin-right: 5rem;
        }

        &-icon {
            height: 5rem;
            width: 5rem;
            object-fit: contain;
            margin-bottom: 1rem;

        }
    }

    &__deals {
        margin-bottom: 5rem;

        &-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            column-gap: 3.5rem;
            row-gap: 3rem;

            @media only screen and (max-width: 920px) {
                grid-template-columns: 1fr 1fr 1fr;
            }

            @media only screen and (max-width: 800px) {
                grid-template-columns: 1fr 1fr ;
            }

            @media only screen and (max-width: 900px) {
                grid-template-columns: 1fr ;

            }
        }
    }

    &__recent {
        margin-bottom: 5rem;

        &-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            column-gap: 3.5rem;
            row-gap: 3rem;

            @media only screen and (max-width: 920px) {
                grid-template-columns: 1fr 1fr 1fr;
            }
            
            @media only screen and (max-width: 800px) {
                grid-template-columns: 1fr 1fr ;
            }

            @media only screen and (max-width: 900px) {
                grid-template-columns: 1fr ;

            }
        }
    }

    &__packages {
        margin-bottom: 5rem;

        &-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            column-gap: 3.5rem;
            row-gap: 3rem;

            @media only screen and (max-width: 920px) {
                grid-template-columns: 1fr 1fr 1fr;
            }

            @media only screen and (max-width: 800px) {
                grid-template-columns: 1fr 1fr ;
            }

            @media only screen and (max-width: 900px) {
                grid-template-columns: 1fr ;

            }
        }
    }
}