*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

body {
    overflow-x:hidden
}

html {
    box-sizing: border-box;
    font-size: 62.5%; //10px/16px = 62.5% (1rem = 10px)
    // scroll-behavior: smooth;
    @media only screen and (max-width: 900px) {
        font-size: 50%;
    }
}

.container {
    display: grid;
    grid-template-columns: [leftgutter-start] minmax(13rem,1fr) [leftgutter-end full-start] repeat(12, [col-start] minmax(min-content, 1fr) [col-end]) [full-end rightgutter-start] minmax(13rem,1fr) [rightgutter-end];
    position: relative;
    background-color: #F1F2F3;
}

.swiper-container {
    // padding-left: 3px;
    height: 100% !important;
}