.explore {
    grid-column: leftgutter-start / rightgutter-end;

    text-align: center;
    margin-bottom: 7rem;
    &__container {
        width: 98.7vw;

        &--grid {
            padding: 0 3.7rem;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            column-gap: 5rem;
        }
    }

    &__slide {
        position: relative;
        width: 100%;
        cursor: pointer;
        &-img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 5px;
        }

        & svg {
            position: absolute;
            top: 50%;
            left: 50%;

            transform: translate(-50%,-50%);
        }
    }
}