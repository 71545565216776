.featured {
    grid-column: leftgutter-start / rightgutter-end;
    background-color: #fff;
    margin-bottom: 7rem;
    width: 98.7vw;
    position: relative;
    &__card {
        display: grid;
        grid-template-columns: 1fr 2fr;

        @media only screen and (max-width: 650px) {
            grid-template-columns: 1fr ;
        }

        &-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            // max-height: 60rem;
        }

        &-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 3rem 13rem 3rem 6rem;
            overflow: hidden;
            position: relative;
            @media only screen and (max-width: 650px) {
                padding: 3rem 3rem 3rem 6rem;
            }
        }

        &-wrap {
            display: flex;
            margin-bottom: 2.7rem;
        }

        &-photos {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            // justify-content: space-between;
            align-items: center;
            column-gap: 3rem;

            @media only screen and (max-width: 1200px) {
                grid-template-columns: 1fr 1fr 1fr;
                row-gap: 3rem;
            }

            @media only screen and (max-width: 800px) {
                grid-template-columns: 1fr 1fr;
                row-gap: 3rem;
            }
            
        }

        &-photo {
            height: 18rem;
            width: 100%;
            object-fit: cover;
            border-radius: 15px;
            &:not(:last-child){
                margin-right: 3rem;
            }

        }
    }

    &__btnWrapper {
        display: flex;
        position: absolute;
        bottom: 2rem;
        left: 28.5%;
        z-index: 5;

        @media only screen and (max-width: 650px) {
            display: none;
        }
    }

    &__leftBtn {
        cursor: pointer;
        margin-right: 2px;
    }

    &__rightBtn {
        cursor: pointer;
    }
}