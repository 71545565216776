.packBook {
    grid-column: leftgutter-start / rightgutter-end;
    height: 100vh;
    width: 100%;
    background-color: #fff;
    display: flex;
  
    @media only screen and (max-width: 500px) {
        flex-direction: column;
    } 

    &__imgBox {
        height: 100%;
        width: 35%;

        @media only screen and (max-width: 500px) {
            width: 100%;
            height: 40%;
        }
    }

    &__img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    &__content {
        height: 100%;
        width: 65%;
        padding: 3rem 1.5rem 3rem 4rem;
        overflow-y: auto;

        @media only screen and (max-width: 500px) {
            width: 100%;
            overflow-y: visible;
            height: max-content;
        }

        &-desc {
            width: 70%;
            margin-bottom: 1.7rem;

            @media only screen and (max-width: 500px) {
                width: 100%;
            }
        }
    }

    &__detail {
        padding: 2rem 5rem;
        box-shadow: 0 3px 6px rgba($color: #000000, $alpha: .16);
        border-radius: 20px;
        width: 50%;
        margin-bottom: 5rem;

        @media only screen and (max-width: 1190px) {
            width: 70%;
        }

        @media only screen and (max-width: 650px) {
            width: 100%;
        }

        &-head {
            display: flex;
            justify-content: space-between;
            margin-bottom: 1rem;
        }

        &-item {
            display: grid;
            grid-template-columns: 1fr 1fr;
            // justify-content: space-between;
            // align-items: center;
        }
    }

    &__guest {
        padding: 2rem 5rem;
        box-shadow: 0 3px 6px rgba($color: #000000, $alpha: .16);
        border-radius: 20px;
        width: 50%;
        margin-bottom: 5rem;

        @media only screen and (max-width: 1190px) {
            width: 70%;
        }

        @media only screen and (max-width: 650px) {
            width: 100%;
        }
    }

    &__pay {
        padding: 2rem 5rem;
        box-shadow: 0 3px 6px rgba($color: #000000, $alpha: .16);
        border-radius: 20px;
        width: 50%;
        margin-bottom: 7rem;

        @media only screen and (max-width: 1190px) {
            width: 70%;
        }

        @media only screen and (max-width: 650px) {
            width: 100%;
        }

        &-icon {
            border-radius: 20px;
            object-fit: contain;
            height: 6rem;
            cursor: pointer;
        }
    }



}