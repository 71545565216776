@font-face {
    font-family: 'helvetica-reg';
    src: url('../../assets/font/HelveticaNeue-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'helvetica-med';
    src: url('../../assets/font/HelveticaNeue-Medium.otf') format('truetype');
}

@font-face {
    font-family: 'helvetica-bold';
    src: url('../../assets/font/HelveticaNeue-Bold.ttf') format('truetype');
}

.banner {

    &-main {
        font-family: 'helvetica-med';
        font-size: 4.2rem;
        line-height: 5rem;
        color: #fff;
    }

    &-sub {
        font-family: 'helvetica-bold';
        font-size: 3.2rem;
        line-height: 3.5rem;
        color: #fff;
    }

    &-cate {
        font-family: 'helvetica-reg';
        font-size: 1.8rem;
        line-height: 1.5rem;
        color: #fff;
        margin-top: 1.3rem;
        // width: min-content;
    }

}

.heading {

    &-main {
        font-family: 'helvetica-med';
        font-size: 3.5rem;
        // line-height: 1.5px;
        color: #050505;
    }

    &-med {
        font-family: 'helvetica-med';
        font-size: 2.9rem;
        line-height: 3rem;
        color: #050505;

        &--wh {
            font-family: 'helvetica-med';
            font-size: 2.9rem;
            line-height: 4rem;
            color: #fff;
        }
    }

    &-sm {
        font-family: 'helvetica-med';
        font-size: 2.1rem;
        line-height: 2rem;
        color: #050505;

        &--wh {
            font-family: 'helvetica-med';
            font-size: 2.1rem;
            line-height: 3rem;
            color: #fff;
        }

    }

 
}

.para {

    &-wh {
        font-family: 'helvetica-med';
        font-size: 1.6rem;
        line-height: 1.5rem;
        color: #fff;
        text-decoration: none;
    }

    &-blk {
        font-family: 'helvetica-med';
        font-size: 1.6rem;
        line-height: 2rem;
        color: #666666;
    }

    &-black {
        font-family: 'helvetica-med';
        font-size: 1.6rem;
        line-height: 2rem;
        color: #050505;
    }

    &-deals {
        font-family: 'helvetica-med';
        font-size: 1.6rem;
        line-height: 2rem;
        color: #E2A724;
    }

    &-small {
        font-family: 'helvetica-med';
        font-size: 1.3rem;
        color: #050505;
        line-height: 25px;
        
        &--bold {
            font-family: 'helvetica-bold';
            font-size: 1.3rem;
            color: #050505;
            line-height: 25px;
        }
    }

    &-cate {
        font-family: 'helvetica-med';
        font-size: 1.4rem;
        line-height: 2rem;
        color: #050505;
        padding: 1.3rem;
        border-radius: 10px;
        box-shadow: 0 3px 6px rgba($color: #000000, $alpha: .16);

        &:not(:last-child){
            margin-right: 1.9rem;
        }
    }

    &-reg {
        font-family: 'helvetica-reg';
        font-size: 2rem;
        line-height: 3.6rem;
        color: #04151F;

    }

}

.test {

    &-name {
        font-family: 'helvetica-med';
        font-size: 2rem;
        color: #000;
        line-height: 2rem;
    }

    &-desc {
        font-family: 'helvetica-med';
        font-size: 2rem;
        color: #777777;
        letter-spacing: 0.2px;
        line-height: 2.4rem;
    }
}

.activity {

    &-name {
        font-family: 'helvetica-med';
        font-size: 2rem;
        color: #050505;
        line-height: 30px;
    }

    &-loc {
        font-family: 'helvetica-med';
        font-size: 1.3rem;
        color: #050505;
        line-height: 20px;
    }
}

.label {

    &-color {
        font-family: 'helvetica-med';
        font-size: 1.3rem;
        line-height: 2rem;
        color: #E6995D;
    }
}

.input {

    &-borNorm {
        padding: 1.7rem;
        border-radius: 10px;
        font-family: 'helvetica-med';
        font-size: 1.3rem;
        line-height: 1.5rem;
        color: #050505;
        border: 1px solid #D6D3D3;
        outline: none;
        width: 100%;

        &:not(:last-child) {
            margin-bottom: 1rem;
        }

        &:focus {
            outline: none;
        }
    }

    &-norm {
        padding: 1.7rem;
        box-shadow: 0 3px 6px rgba($color: #000000, $alpha: .16);
        border-radius: 2px;
        font-family: 'helvetica-med';
        font-size: 1.3rem;
        line-height: 1.5rem;
        color: #050505;
        border: none;
        outline: none;
        width: 100%;

        &:focus {
            outline: none;
        }
    }
}

.react-datepicker-ignore-onclickoutside, .react-datepicker__input-container>input {
    padding: 1.7rem !important;
    box-shadow: 0 3px 6px rgba($color: #000000, $alpha: .16) !important;
    border-radius: 2px !important;
    font-family: 'helvetica-med' !important;
    font-size: 1.3rem !important;
    line-height: 1.5rem !important;
    color: #050505 !important;
    border: none !important;
    outline: none !important;
    width: 100% !important;

    &:focus {
        outline: none !important;
    }
}