.prodCard {
    border: 1px solid #70707063;
    max-width: max-content;
    transition: all .2s ease-in;
    cursor: pointer;
    &:hover {
        border: 1px solid transparent;
        box-shadow: 0 3px 6px rgba($color: #000000, $alpha: .16);
    }


    // &:not(:last-child) {
    //     margin-right: 3rem;
    // }

    &__img {
        width: 100%;
        object-fit: cover;
    }

    &__content {
        padding: 10px 35px 20px 15px;

    }

    &__rating {
        margin-bottom: 1rem;

        & svg {
            cursor: pointer;
            &:not(:last-child){
                margin-right: 5px;
            }
        }
    }

    &__link {
        text-decoration: none;
    }
}