@keyframes fadeIn {
    0% {
        opacity: 0;
    }

  
    100% {
        opacity: 1;
    }
}

.navbar {
    display: flex;
    align-items: center;
    position: absolute;
    position: fixed;
    top: 0;
    left: 0;
    padding: 0 10.7rem;
    z-index: 999;
    width: 100vw;
    // background-color: rgba($color: #E2A724, $alpha: 6);
    background-color: transparent;
    color: white;
    font-family: Helvetica;
    font-weight: 300;
    mix-blend-mode: difference;

    @media only screen and (max-width: 700px) {
        padding: 0 3.7rem;
    }

    &__sticky {
        mix-blend-mode: normal;
        background-color: #E2A724;
        transform-origin: top;
        animation: fadeIn .6s ;
        box-shadow: 0 3px 6px rgba(#000000, .16);
        transition: box-shadow .4s ease-in-out;

    }

}

.ReactModal__Overlay {
    z-index: 99999;
}

.navbar__title {
    margin-right: auto;
    font-size: 2.1rem !important;
    padding: 12px 16px;
    font-family: 'helvetica-bold';

    @media only screen and (max-width: 360px) {
        font-size: 1.8rem;
    }
}

.navbar__item {
    padding: 16px 16px;
    cursor: pointer;
    vertical-align: middle;
    background: transparent;
    // text-shadow: white 0px 0px 10px;
    color: #fff;
    text-decoration: none;
    font-family: 'helvetica-med';
    font-size: 1.8rem;
    white-space: nowrap;

    @media only screen and (max-width: 360px) {
        font-size: 1.5rem;
    }
}