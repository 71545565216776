.popular {
    grid-column: full-start/full-end;

    @media only screen and (max-width: 420px) {
     grid-column: leftgutter-start/rightgutter-end;
        margin: 0 4rem;
    }

    &__wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        width: 100%;
        column-gap: 3rem;
        justify-content: space-between;

        @media only screen and (max-width: 1000px) {
            grid-template-columns: 1fr 1fr 1fr;
            row-gap: 3rem;
        }

        @media only screen and (max-width: 730px) {
            grid-template-columns: 1fr 1fr;
            // row-gap: 3rem;
        }

        @media only screen and (max-width: 550px) {
            grid-template-columns: 1fr;
            // row-gap: 3rem;
        }
    }
}