.testimonials {
    grid-column: full-start / full-end;
    margin-bottom: 5rem;
    width: 100%;

    @media only screen and (max-width: 570px) {
        grid-column: leftgutter-start / rightgutter-end;

    }

    &__slider {
        width: 75vw;
        margin: auto;
        position: relative;

        @media only screen and (max-width: 830px) {
            width: 70vw;
        }

        @media only screen and (max-width: 570px) {
            width: 65vw;
        }

    }

    &__slide {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    
        &-img {
            height: 15rem;
            width: 15rem;
            border-radius: 50%;
            margin-bottom: 1.5rem;
        }
    }

    &__arrLeft {
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        height: max-content;
        width: max-content;
        z-index: 3;
    }

    &__arrRight {
        cursor: pointer;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        height: max-content;
        width: max-content;
        z-index: 3;
    }
}