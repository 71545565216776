.packDet {
    grid-column: leftgutter-start / rightgutter-end;
    height: 100vh;
    width: 100%;
    background-color: #fff;
    display: flex;
    padding-top: 5rem;
    position: relative;

    @media only screen and (max-width: 610px) {
        flex-direction: column;
    height: 100%;
    // margin-bottom: 5rem;
    }

    &__imgBox {
        height: 100%;
        width: 35%;

        @media only screen and (max-width: 610px) {
            width: 100%;
            height: 30%;
        }

    }

    &__img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    &__details {
        height: 100%;
        width: 65%;
        padding: 3rem 1.5rem 3rem 4rem;
        overflow-y: auto;

        @media only screen and (max-width: 610px) {
            width: 100%;
            overflow: visible;
        }

        &-para {
            margin-bottom: 1.7rem;
            @media only screen and (max-width: 610px) {
                width: 100%;
                
            }
        }
    }

    &__duration {
        margin-top: 5rem;
        margin-bottom: 6rem;
    }

    &__incl {
        
        &-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            column-gap: 2rem;
            row-gap: 3rem;

            @media only screen and (max-width: 1130px) {
                grid-template-columns: 1fr 1fr 1fr;
            }

            @media only screen and (max-width: 710px) {
                grid-template-columns: 1fr 1fr;
            }

        }

        &-card {
            padding: 2rem 3rem 2rem 2rem;
            box-shadow: 0 3px 6px rgba($color: #000000, $alpha: .16);
            border-radius: 6px;
            width: 100%;
        }
    }

    &__quickDate {
        margin-top: 5rem;

        &-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: 2rem;
            row-gap: 3rem;

            @media only screen and (max-width: 760px) {
                grid-template-columns: 1fr 1fr;
            }
        }
    }

    &__date {
        margin-top: 5rem;
        margin-bottom: 5rem;

        &-wrapper {
            width: 100%;
            box-shadow: 0 3px 6px rgba($color: #000000, $alpha: .16);
            padding: 3rem 4rem 3.5rem 2rem;

            display: flex;
        }

        &-field {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;

            &:not(:last-child) {
                margin-right: 1.6rem;
            }
        }
    }

    &__images {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        column-gap: 2rem;
        row-gap: 3rem;

        @media only screen and (max-width: 760px) {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }

    &__image {
        border-radius: 5px;
        object-fit: cover;
        width: 100%;
        height: 15rem;
        cursor: pointer;
    }

    &__bg {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
    }

    &__bgOverlay {
        z-index: 2;
        background-image:  linear-gradient(105deg, rgba(#E0E0E0, 0.6) 0%, rgba(#E0E0E0, 0.6) 50%, transparent 50%);
        position: relative;
        height: 100%;
        width: 100%;
    }

    &__modal {
        position: absolute;
        top: 40px;
        left: 40px;
        right: 40px;
        bottom: 40px;
        background-color: #fff;
        background-size: cover;
        // height: 60vh;
        width: 70vw;
        height: 80vh;

        @media only screen and (max-width: 1080px) {
            width: 75vw;
            height: 85vh;
            background-image: linear-gradient(
                105deg, rgba(#E0E0E0, 0.6) 0%, rgba(#E0E0E0, 0.6) 100%, transparent 100%), url(../../assets/img/banner.png);
        }

        &-overlay {
            background-color: rgba($color: #000000, $alpha: 0.45);
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
    }

    &__form {
        width: 50%;
        padding: 6rem;
        position: relative;
        z-index: 3;
        @media only screen and (max-width: 1080px) {
            width: 100%;
            
        }
    }

    // &__popDetails {
    //     background: rgba($color: #e0e0e0, $alpha: .7);
    //     box-shadow:  20px 20px 60px #bebebe,
    //          -20px -20px 60px #ffffff;
    //     height: 5rem;
    //     width: 5rem;
    // }
    
}