.offer {
    grid-column: leftgutter-start/rightgutter-end;
    background-color: #fff;
    
    display: grid;
    grid-template-columns: [leftgutter-start] minmax(13rem,1fr) [leftgutter-end full-start] repeat(12, [col-start] minmax(min-content, 1fr) [col-end]) [full-end rightgutter-start] minmax(13rem,1fr) [rightgutter-end];
    grid-gap: 1.6;
    padding: 6rem 0;
    margin-bottom: 7rem;

    &__container {
        grid-column: full-start / full-end;
        text-align: center;

        display: flex;
        flex-direction: column;
        align-items: center;

        @media only screen and (max-width: 520px) {
            grid-column: leftgutter-start / rightgutter-end;
            margin: 0 3rem;


        }
    }

    &__para {
        width: 70%;

        @media only screen and (max-width: 650px) {
            width: 100%;

        }
    }

    &__content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 2.8rem;
        row-gap: 2.5rem;

        @media only screen and (max-width: 1000px) {
            grid-template-columns: 1fr 1fr;
        }

        @media only screen and (max-width: 650px) {
            grid-template-columns: 1fr;
        }
    }

    &__card {
        width: 100%;
        position: relative;
        border-radius: 15px;

        &-img {
            height: 100%;
            width: 100%;
            border-radius: 15px;
            object-fit: cover;
        }

        &-text {
            position: absolute;
            bottom: 2rem;
            left: 2rem;
            text-align: left;
        }

    }

}