.hotDealCard {
    width: 100%;
    position: relative;
    border-radius: 25px;

    &__img {
        height: 100%;
        width: 100%;
        border-radius: 25px;
        object-fit: cover;
        object-position: center;
    }

    &__text {
        position: absolute;
        bottom: 2rem;
        left: 2rem;
        text-align: left;
    }
}