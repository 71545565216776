.home {

    &__categories {
        grid-column: leftgutter-start / rightgutter-end;
        background-color: #fff;

        display: grid;
        grid-template-columns: [leftgutter-start] minmax(13rem,1fr) [leftgutter-end full-start] repeat(12, [col-start] minmax(min-content, 1fr) [col-end]) [full-end rightgutter-start] minmax(13rem,1fr) [rightgutter-end];
        grid-gap: 1.6;
        padding: 6rem 0;
        margin-bottom: 7rem;
        row-gap: 5rem;
    }
}