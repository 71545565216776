.deals {
    grid-column: leftgutter-start / rightgutter-end;
    background-color: #fff;

    display: grid;
    grid-template-columns: [leftgutter-start] minmax(13rem,1fr) [leftgutter-end full-start] repeat(12, [col-start] minmax(min-content, 1fr) [col-end]) [full-end rightgutter-start] minmax(13rem,1fr) [rightgutter-end];
    grid-gap: 1.6;
    padding: 6rem 0;
    margin-bottom: 7rem;
    @media only screen and (max-width: 650px) {
        display: block;
    }
    &__row {
        grid-column: full-start / full-end;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:not(:last-child) {
            margin-bottom: 4.5rem;
        }

        @media only screen and (max-width: 1080px) {
            flex-direction: column;
            // align: flex-start;
            text-align: center;
        }
    }

    &__text {
        width: 20%;

        @media only screen and (max-width: 1080px) {
            width: 80%;
            margin-bottom: 3rem;
        }
    }

    &__slider {
        width: 60vw;
        position: relative;

        @media only screen and (max-width: 1080px) {
            width: 74.7vw;
        }

        @media only screen and (max-width: 800px) {
            width: 60.7vw;
        }
    }

    &__slide {
        border: 1px solid #70707063;
        width: 100%;
        cursor: pointer;
        transition: all .2s ease-in;
        // margin: 1.5rem;
        // padding: 1rem;

        // &:hover {
        // transform: translateY(-1rem);
        // }
        
        &--text {
            padding: 10px 35px 20px 15px;
        }

        &-img {
            width: 100%;
            object-fit: cover;
        }
    }

    &Slider__leftBtn {
        z-index: 3;
        padding: 1rem .5rem;
        background-color: rgba($color: #000000, $alpha: .46);
        cursor: pointer;
        position: absolute;
        top: 50%;
        left: 1rem;
        transform: translateY(-50%);
        height: max-content;
        width: max-content;
        border-radius: 0 10px 10px 0;
        
        & svg {
            height: 2rem;
            width: 2rem;
        }
    }

    &Slider__rightBtn {
        z-index: 3;
        padding: 1rem .5rem;
        background-color: rgba($color: #000000, $alpha: .46);
        cursor: pointer;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        height: max-content;
        width: max-content;
        border-radius: 0 10px 10px 0;
        
        & svg {
            height: 2rem;
            width: 2rem;
        }
    }

    &__link {
        text-decoration: none;
    }
}

// .swiper-container {
//     // margin: 1.5rem !important;
//     margin-left: -.4rem !important;
//     margin-right: -.4rem !important;
// }

// .swiper-wrapper {
//     margin-bottom: 1.5rem !important;
//     // margin-right: -.3rem !important;
// }

