.btn {

    &-color {
        outline: none;
        border: none;
        background-color: #EBB12C;
        border-radius: 5px;
        cursor: pointer;
        font-family: 'helvetica-med';
        font-size: 1.8rem;  
        padding: .7rem 3rem;
        color: #050505;        

        &:active,
        &:focus {
            outline: none;
        }
    }

    &-colText {
        cursor: pointer;
        font-family: 'helvetica-med';
        font-size: 1.3rem;
        line-height: 2rem;
        color: #E2A724;
        background-color: transparent;
        text-decoration: none;
        border: none;
        outline: none;

        &:active,
        &:focus {
            outline: none;
        }
    }

    &-social {
        font-family: 'helvetica-med';
        font-size: 1.8rem;  
        padding: .7rem 1.5rem;
        border-radius: 1.3rem;
        color: #fff;
        border: none;
        cursor: pointer;
        text-decoration: none;
        &:active,
        &:focus {
            outline: none;
        }
    }

    &-explore {
        display: flex;
        text-decoration: none;
        background-color: transparent;
        position: absolute;
        bottom: 3rem;
        right: 13rem;
        display: flex;
        align-items: center;
        
        @media only screen and (max-width: 650px) {
            right: 3rem;
        }

        & p {
            transition: all .2s ease-in-out;

        }

        &:hover p {
            // margin-right: -1rem;
            transform: translateX(1rem);
        }

        &--btnSvg {
            background-color: #EBB12C;
            border-radius: 25px;
            padding: 2.3rem 2.6rem;
            margin-left: 2rem;
            transition: all .2s ease-in-out;
        }

        &:hover .btn-explore--btnSvg {
            // margin-right: -1rem;
            transform: translateX(-.5rem);
        }

    }

    &-operBtn {
        font-size: 20px;
        font-family: 'helvetica-med';
        padding: 0;
        margin: 0;
        background-color: transparent;
        border: none;
        outline: none;
        transition: all .2s ease-in;
        cursor: pointer;
        color: #fff;
        height: max-content;

        &:hover {
            color: #EBB12C;
        }
    }
}