.header {
    grid-column: leftgutter-start / rightgutter-end;
    margin-bottom: 9rem;
    width: 100%;
    // overflow: hidden;
    @media only screen and (max-width: 830px) {
        width: 100vw;
    }

    &__cards {
        margin: 0 13rem;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        // align-items: center;
        // justify-content: space-between;
        margin-top: -12rem;
        position: relative  ;
        z-index: 2;

        @media only screen and (max-width: 1350px) {
            margin: 0 7rem;
            margin-top: -11rem;
        }

        @media only screen and (max-width: 830px) {
            // margin: 0 7rem;
            width: 90vw;
            display: block;
            margin: 0 3rem;
            margin-top: -11rem;

        }

    }

    &__card {
        width: 26rem;
        box-shadow: 0px 3px 6px rgba($color: #000000, $alpha: .16);

        @media only screen and (max-width: 1350px) {
            width: 80%;
        }

        @media only screen and (max-width: 830px) {
            width: 100%;
        }

        &-img {
            width: 100%;
            object-fit: cover;
            margin-bottom: -5px;
        }

        &-cta {
            padding: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: max-content;
        }

        &-arr {
            padding: .6rem 1.5rem;
            background-color: #fff;
            border-radius: 2.5rem;
        }

        & p {
            transition: all .3s ease-in-out;
        }

        &:hover p {
            // transform: translateX(99%);
            margin-left: 5%;
        }
    }

}

.banner {
    height: 80vh;
    width: 98.7vw;
    position: relative;

    @media only screen and (max-width: 650px) {
        width: 100vw;
    }

    &__overlay {
        background-color: rgba($color: #000000, $alpha: .2);
        height: 80vh;
        width: 98.7vw;
        position: absolute;
        top: 0;
        left: 0;
        
        @media only screen and (max-width: 650px) {
            width: 100vw;
        }
    }

    &__slide {
        height: 100%;
        width: 100%;
    }

    &__img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
    }

    &__text {
        position: absolute;
        top: 40%;
        right: 15%;

        @media only screen and (max-width: 400px) {
            // flex-direction: column;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            // width: 100%;
            // white-space: nowrap;
            width: 80%;

        }
    }

    &__leftBtn {
        position: absolute;
        left: 3.5rem;
        top: 50%;
        transform: translateY(-50%) rotate(180deg);
        z-index: 3;
        cursor: pointer;
    }

    &__rightBtn {
        position: absolute;
        right: 3.5rem;
        top: 50%;
        transform: translateY(-50%);
        z-index: 3;
        cursor: pointer;
    }
}
